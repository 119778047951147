import React from 'react';
import PropTypes from 'prop-types';
import BasicStyledText from '../basicStyledText';

const Schedule = (props) => {

  const { schedule, t } = props;

  function displayChair(item) {
    if ('chair' in item) {
      return (
        <p className="con-text__xs--margin-bottom-2">
          <span className="con-basic-styling-bold">{t('Pages.Schedule.Chair')}</span> {item.chair}
        </p>
      );
    }
  }

  function displayAbstract(item) {
    if ('abstract' in item) {
      return (
        <p className="con-text__xs">
          <span className="con-basic-styling-bold">{t('Pages.Schedule.Abstract')}</span> {item.abstract}
        </p>
      );
    }
  }

  function displayEmcee(item) {
    if ('emcee' in item) {
      return (
        <p className="con-text__xs">
          <span className="con-basic-styling-bold">{t('Pages.Schedule.Emcee')}</span> {item.emcee}
        </p>
      );
    }
  }

  function displayDragons(item) {
    if ('dragons' in item) {
      return (
        <div>
        <span className="con-basic-styling-bold con-text__xs">{t('Pages.Schedule.Dragons')}</span>
        {item.dragons.map((dragon) => (
          <p key={dragon.id} className="con-text__xs">
            {dragon.name}
          </p>
        ))}
      </div>
      );
    }
  }

  function displayStartups(item) {
    if ('startups' in item) {
      return (
        <div>
        <span className="con-basic-styling-bold con-text__xs">{t('Pages.Schedule.Startups')}</span>
        {item.startups.map((startup) => (
          <p key={startup.id} className="con-text__xs">
            {startup.name}
          </p>
        ))}
      </div>
      );
    }
  }

  return (
    <div className="con-schedule">
      <ul className="con-schedule__list">
        {
          schedule.map((item) => {
              return (
                <li className="con-schedule__list--item"
                    id={`schedule-item-${item.id}`}
                    key={item.id}
                >
                  <span className="con-bullet-point" />
                  <p className="con-heading__xs--bold">{item.time}</p>
                  <p className="con-heading-schedule__xs--bold">{item.title}</p>
                  <p className="con-heading-schedule__xs--development">{item.developmentTitle}</p>
                  <p className="con-heading-schedule__xs--security">{item.securityTitle}</p>
                  {displayChair(item)}
                  <p className="con-heading-schedule__xs--bold">{item.title2}</p>
                  {
                    item.speakers.map((speakerObject) => {
                      return (
                        <p key={speakerObject.id} className="con-text__xs--margin-bottom-1">
                          <BasicStyledText item={speakerObject}/>
                        </p>
                      );
                    })
                  }
                  {displayAbstract(item)}
                  {displayEmcee(item)}
                  {displayDragons(item)}
                  {displayStartups(item)}

                </li>
              );
            }
          )}
      </ul>
    </div>
  );
};

Schedule.propTypes = {
  schedule: PropTypes.array,
  t: PropTypes.func
};

export {Schedule};
