import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import expleoMeeting from '../../../assets/images/expleo_meeting.webp';

const ExpleoMeetingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.BeltechEduConference.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <div className="con-flex__container--left">
            <h1 className="con-heading con-heading__l--margin-bottom-3">
              {t('Pages.ExpleoMeeting.Header')}
            </h1>
          </div>
        </div>
        <img
          className="con-edu-conference-image"
          src={expleoMeeting}
          alt="Beltech 2023 Expleo Meeting page"
        />
      </div>
    </div>
  );
};

export default ExpleoMeetingPage;
