import React, { useEffect, useState } from 'react';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import { Schedule } from '../../schedule';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import morningSchedule from '../../../assets/static-data/2024/schedule.json';
import developerSchedule from '../../../assets/static-data/2024/developerSchedule.json';
import lunchSchedule from '../../../assets/static-data/2024/lunchSchedule.json';
import securitySchedule from '../../../assets/static-data/2024/securitySchedule.json';
import eveningSchedule from '../../../assets/static-data/2024/eveningSchedule.json';

const SchedulePage = () => {

  const { t } = useTranslation();
  const [schedule, setSchedule] = useState(morningSchedule.schedules);
  const [isDeveloper, setDeveloperSchedule] = useState(developerSchedule.schedules);
  const [isLunch, setLunchSchedule] = useState(lunchSchedule.schedules);
  const [isSecurity, setSecuritySchedule] = useState(securitySchedule.schedules);
  const [isEvening, setEveningSchedule] = useState(eveningSchedule.schedules);

  useEffect(() => {
    document.title = t('Pages.Schedule.Title');

    if (!schedule) {
      setSchedule(morningSchedule);
    }

    if (!isDeveloper) {
      setDeveloperSchedule(developerSchedule);
    }

    if (!isLunch) {
      setLunchSchedule(lunchSchedule);
    }

    if (!isSecurity) {
      setSecuritySchedule(securitySchedule);
    }

    if (!isEvening) {
      setEveningSchedule(eveningSchedule);
    }
  }, []);

  if (featureToggles.schedule()) {
    return <ComingSoonPage title={t('Pages.Schedule.Title')} header={t('Pages.2025Schedule.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.2025Schedule.Header')}</h1>
        </div>
        <h3 className="con-heading__s--margin-bottom-1">{t('Pages.2024Schedule.VenueLocation')}</h3>
        <Schedule schedule={schedule} t={t}/>
        <Schedule schedule={isDeveloper} t={t}/>
        <Schedule schedule={isLunch} t={t}/>
        <Schedule schedule={isSecurity} t={t}/>
        <Schedule schedule={isEvening} t={t}/>
      </div>
    </div>
  );
};

SchedulePage.propTypes = {
  scheduleList: PropTypes.array,
  t: PropTypes.func
};

export default SchedulePage;
