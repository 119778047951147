import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import hospitalityFlyer from '../../../assets/images/Beltech_hospitality_2024.webp';

const HospitalityPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Home.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
        </div>
        <img
          className="con-save-date-image"
          src={hospitalityFlyer}
          alt="client"
        />
      </div>
    </div>
  );
};

export default HospitalityPage;
