import React from 'react';
import PropTypes from 'prop-types';

const PartnerCard = (props) => {
  const { imageUrl, name, description, learnMore } = props;

  return (
    <li className="con-partner-card">
      <div className="con-partner-image__container">
        <img className="con-partner-image__src" src={imageUrl} alt={`${name} logo`} />
      </div>
      <div className="con-partner-text">
        <h3 className="con-heading__m--centered">{name}</h3>
        <p className="con-text__xs--centered">{description}</p>
        <p className="con-text__xs--centered">{learnMore && learnMore.textBefore}</p>
        <a className="con-link__partners--centered" href={learnMore && learnMore.url}>{learnMore && learnMore.url}</a>
        <p className="con-text__xs--centered">{learnMore && learnMore.textAfter}</p>
        <a className="con-link__partners--centered" href={learnMore && learnMore.email}>{learnMore && learnMore.email}</a>
        <p className="con-text__xs--centered">{learnMore && learnMore.textEnd}</p>
      </div>
    </li>
  );
};

PartnerCard.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  learnMore: PropTypes.shape({
    textBefore: PropTypes.string,
    url: PropTypes.string,
    textAfter: PropTypes.string,
    email: PropTypes.string,
    textEnd: PropTypes.string
  })
};

export { PartnerCard };
