import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MagazinePDF } from '@kainos-applied-innovation/convention-frontend-components/dist/components/magazinePDF';

const MagazinePage = () => {

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.Magazine2024.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Magazine2024.Header')}</h1>
        </div>

        <MagazinePDF magazineUrl={'/assets/images/2024/2024.pdf'} />

      </div>
    </div>
  );
};

export { MagazinePage };
