import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import codeOfConduct from '../../assets/BelTechCodeofConduct.pdf';

function openPdf() {
  window.open(codeOfConduct);
}
const Footer = () => {
  return (
    <div className="con-footer">
      <div className="con-footer__top-row">
        <h2 className="con-footer__heading">&copy; 2024 BelTech</h2>
        <div className="con-social-media">
          <a className="con-social-media__icons" href="https://twitter.com/beltech2024" target="_blank" rel="noreferrer noopener">
            <span className="con-visually-hidden">Link to BelTech Twitter page</span>
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </div>
      </div>
      <a className="con-footer__link" onClick={openPdf}>Click here for Code of Conduct</a>
    </div>
  );
};

export default Footer;
