import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('Pages.NotFound.Title');
  }, []);

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.NotFound.Header')}</h1>
        </div>
        <p className="con-text__s--margin-bottom-2">
        {t('Pages.NotFound.Message')}
        </p>
        <Link className="con-text con-text__link--underlined" to="/">{t('Pages.NotFound.HomePageLink')}</Link>
      </div>
    </div>
  );
};

export default NotFound;
