const featureToggles = {
  // todo: implement the right feature toggle here
  soldOut: () => false,
  register: () => true,
  schedule: () => true,
  archive: ()=> true,
  partners: () => true,
  contactFormAuth: () => true,
  liveStream: () => true,
  pressReleases: () => true,
  dragonsDen: () => true,
  eduConference: () => true
};
export default featureToggles;
