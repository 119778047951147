import React from 'react';
import PropTypes from 'prop-types';

const BasicStyledText = (props) => {

  const item = props.item;

  if (!item) return;

  let cssClass = 'con-basic-styling';
  if (item.bold && item.bold === true) {
    cssClass += '-bold';
  }

  if (item.italics && item.italics === true) {
    cssClass += '-italic';
  }

  return (<span className={cssClass}>{item.text}</span>);

};

BasicStyledText.propTypes = {
  item: PropTypes.object
};

export default BasicStyledText;
