import React, { useEffect } from 'react';
import { PartnerRenderListOfCards } from '../../partnerCard/partnerCardUtils';
import PropTypes from 'prop-types';
import featureToggles from '../../../utils/featureToggles';
import ComingSoonPage from '../comingSoonPage';
import dragonImage1 from '../../../assets/images/dragons/Seamus_Cushley.webp';
import dragonImage2 from '../../../assets/images/dragons/Adrienne_Hanna.webp';
import dragonImage3 from '../../../assets/images/dragons/Alison_Coote.webp';
import emceeImage1 from '../../../assets/images/dragons/John_Ferris.webp';
import './index.scss';

const DragonsDenCompaniesPage = (props) => {

  const { startups, t } = props;

  useEffect(() => {
    document.title = t('Pages.DragonsDenCompanies.Title');
  }, []);

  if (featureToggles.dragonsDen()) {
    return <ComingSoonPage header={t('Pages.DragonsDenCompanies.Header')} />;
  }

  return (
    <div className="con-container">
      <div className="con-content">
        <div className="con-flex__container">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.DragonsDenCompanies.Header')}</h1>
        </div>
        <p className="con-text"> {t('Pages.DragonsDenCompanies.Introduction')} </p>
        <div className="con-flex__container emcee-banner">
          <div className="emcee-text">
            <p className="con-text__s--margin-bottom-2--bold emcee-subheader">{t('Pages.DragonsDenCompanies.Subheader')}</p>
            <p className="con-text__s--margin-bottom-2--bold emcee-text">{t('Pages.DragonsDenCompanies.Competition')}</p>
          </div>
          <div className="emcee-container">
            <img className="emcee-image" src={emceeImage1} alt="Beltech-Emcee-2024"/>
            <p className="con-text__s--margin-bottom-2--bold emcee-text">{t('Pages.DragonsDenCompanies.Emcee')}</p>
          </div>
        </div>
        <h3 className="con-heading__m--centered">{t('Pages.DragonsDenCompanies.DragonsSubheader')}</h3>
        <div className="dragon-image-container">
          <div className="dragon-image-label-container">
            <img src={dragonImage1} alt="Beltech-Dragon-2024" />
            <p className="dragon-image-label con-text">{t('Pages.DragonsDenCompanies.Dragon1')}</p>
          </div>
          <div className="dragon-image-label-container">
            <img src={dragonImage2} alt="Beltech-Dragon-2024" />
            <p className="dragon-image-label con-text">{t('Pages.DragonsDenCompanies.Dragon2')}</p>
          </div>
          <div className="dragon-image-label-container">
            <img src={dragonImage3} alt="Beltech-Dragon-2024" />
            <p className="dragon-image-label con-text">{t('Pages.DragonsDenCompanies.Dragon3')}</p>
          </div>
        </div>
        <p className="con-text"> {t('Pages.DragonsDenCompanies.Startups')} </p>
        {PartnerRenderListOfCards(startups)}
      </div>
    </div>
  );
};

DragonsDenCompaniesPage.propTypes = {
  startups: PropTypes.object,
  t: PropTypes.func
};

export default DragonsDenCompaniesPage;
