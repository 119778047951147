import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImageCarousel from '../../imageCarousel';

const SoldOutPage = (props) => {

  const { t } = useTranslation();
  let { title} = props;

  title = title || t('Pages.Schedule.Title');
  

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="con-container parent-grid">
      <div className="con-content left-box">
        <div className="con-flex__container headertext">
          <h1 className="con-heading con-heading__l--margin-bottom-3">{t('Pages.Register.Header')}</h1>
        </div>
        
        <p className="con-text__xs--margin-bottom-2">
          {t('Pages.Register.SoldOut')}
        </p>      </div>
      <div className="con-content right-box">
        <ImageCarousel />
      </div>
    </div>
  );
};

SoldOutPage.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string
};

export default SoldOutPage;
