import React from 'react';
import { PartnerCard } from './index';

const PartnerRenderListOfCards = (partners) => {
  return <ul className='con-partner-wrapper'>
    {partners.map((item) => {
      return <PartnerCard
        key={item.partnerId}
        imageUrl={item.imageUrl}
        name={item.name}
        description={item.description}
        learnMore={item.learnMore}
      />;
    })}
  </ul>;
};

export { PartnerRenderListOfCards };
